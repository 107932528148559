import React from 'react'
import { useInView } from 'react-intersection-observer'

import {
  azCarsAndCoffeeCallout,
  neCarsAndCoffeeCallout,
} from '@speedwaymotors/clutch/Molecules/CalloutSection/calloutConstants'
import { useUserStore } from '@speedwaymotors/clutch/Stores/UserStore/UserStore'

import CalloutCard from 'Clutch/Organisms/CalloutCard/CalloutCard'

import styles from './MasonrySection.module.scss'

const MasonrySection = ({
  leftCallout,
  topRightCallout,
  bottomRightCallout,
  suppressGeoCallout = false,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  })

  const zipCode = useUserStore((x) => x.context.zipCode)

  let isNebraskaZip,
    isArizonaZip = false

  if (
    (zipCode >= 68001 && zipCode <= 69367) ||
    (zipCode >= 50000 && zipCode <= 52807)
  ) {
    //ne + iowa
    isNebraskaZip = true
  } else if (zipCode >= 85001 && zipCode <= 86556) {
    isArizonaZip = true
  }

  //handle seasonality
  if (isNebraskaZip || isArizonaZip) {
    const date = new Date()
    const month = date.getMonth()

    if (isNebraskaZip) {
      //November to March
      if (
        month == 10 ||
        month == 11 ||
        month == 0 ||
        month == 1 ||
        month == 2
      ) {
        isNebraskaZip = false
      }
    } else if (isArizonaZip) {
      //May to August
      if (month == 4 || month == 5 || month == 6 || month == 7) {
        isArizonaZip = false
      }
    }
  }

  const geoCallout =
    isNebraskaZip && !suppressGeoCallout
      ? neCarsAndCoffeeCallout
      : isArizonaZip
        ? azCarsAndCoffeeCallout
        : bottomRightCallout

  return (
    <>
      <div ref={ref} />
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <CalloutCard
            {...leftCallout}
            imageLoading={!inView}
            className={styles.fullHeight}
          />
        </div>
        <div className={styles.right}>
          <CalloutCard {...topRightCallout} imageLoading={!inView} />
          <CalloutCard {...geoCallout} imageLoading={!inView} />
        </div>
      </div>
    </>
  )
}

export default MasonrySection
