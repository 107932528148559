import React from 'react'
import ClassNames from 'classnames'
import MakerEnhance from 'maker-enhance'
import Head from 'next/head'

import styles from './Maker.module.scss'

const Maker = ({ heightConfig, light = false, gradient = false }) => {

  const isCient = typeof window !== 'undefined'
  return (
    <div
      className={ClassNames(
        `${!light ? styles.darkMaker : ''} ${styles.makerContainer} ${heightConfig ? styles.makerContainerWithConfig : ''}`,
      )}
      style={heightConfig ?? {}}
    >
      {gradient ? <div className={styles.makerGradient}></div> : false}
      <MakerHead/>
      <MemoMakerContent isCient={isCient}/>
    </div>
  )
}

const MakerContent = ({isCient}) => {

  if(!isCient) {
    return null
  }

  return (
    <MakerEnhance
      user={'speedway'}
    />
  )
}

const MakerHead = () => {
  <Head>
    <link
      rel="preload"
      href="https://app.maker.co/enhance/speedway.js"
      as="script"
    />
  </Head>
}

const MemoMakerContent = React.memo(MakerContent)

export default Maker
