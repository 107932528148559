import TopVehiclesGrid from '@speedwaymotors/clutch/Containers/TopVehiclesGrid'
import CalloutSection from '@speedwaymotors/clutch/Molecules/CalloutSection/CalloutSection'

import AudienceTag from '../components/audience-tag/AudienceTag'
import HeadContent from '../components/head-content/HeadContent'
import HeroSection from '../components/hero-section/HeroSection'
import Maker from '../components/maker/Maker'
import CategorySection from '../components/modular-sections/category-section/CategorySection'
import MasonrySection from '../components/modular-sections/masonry-section/MasonrySection'
import RecBlock from '../components/modular-sections/rec-block/RecBlock'
import UgcBlock from '../components/modular-sections/ugc-block/UgcBLock'

export {
  HeroSection,
  HeadContent,
  AudienceTag,
  Maker,
  TopVehiclesGrid,
  RecBlock,
  UgcBlock,
  CategorySection,
  CalloutSection,
  MasonrySection,
}
