import { useEffect } from 'react'

import { useUserStore } from '@speedwaymotors/clutch/Stores/UserStore/UserStore'
import { LocalBusinessJsonLd, LogoJsonLd, NextSeo } from 'next-seo'
import Head from 'next/head'

const HeadContent = ({ canonicalUrl, content, preLoadMaker = true }) => {
  const logRocketInitialized = useUserStore(
    (x) => x.context.logRocketInitialized,
  )
  const noIndexNoFollow = false
  const title = content?.title
    ? `${content.title} | Speedway Motors`
    : 'Speedway Motors | Performance Auto Parts & Expert Support'
  const description =
    content?.metaDescription ??
    'Quality Parts for Muscle Car, Hot Rod, Racing & more. Trusted by enthusiasts since 1952 for fast & reliable shipping on affordable parts with expert support.'

  useEffect(() => {
    if (logRocketInitialized) {
      // Logging for LogRocket to pick things up as no other filter seems to be working
      console.log('HP Next App Enabled')
    }
  }, [logRocketInitialized])

  return (
    <>
      {preLoadMaker && (
        <Head>
          <link
            rel="preload"
            href="https://app.maker.co/enhance/speedway.js"
            as="script"
          />
          <link rel="preconnect" href="https://res.cloudinary.com/" />
        </Head>
      )}
      <NextSeo
        title={title}
        description={description}
        canonical={canonicalUrl}
        noindex={noIndexNoFollow}
        nofollow={noIndexNoFollow}
        openGraph={{
          url: canonicalUrl,
          title,
          description,
          images: [
            {
              url: 'https://content.speedwaymotors.com/OtherImages/SpeedwayMotors_Full_FullColor112619.jpg',
              width: '1280',
              height: '300',
              alt: 'Speedway Motors Logo',
            },
          ],
        }}
        additionalMetaTags={[
          {
            name: 'viewport',
            content: 'width=device-width,minimum-scale=1,initial-scale=1',
          },
          {
            name: 'language',
            content: 'english',
          },
          {
            name: 'format-detection',
            content: 'telephone=no',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'shortcut icon',
            href: '/favicon.ico',
          },
          {
            rel: 'icon',
            href: '/favicon.ico',
            sizes: '152x152',
          },
        ]}
      />
      <LocalBusinessJsonLd
        type="Store"
        name="Speedway Motors"
        description={description}
        url={'https://www.speedwaymotors.com'}
        telephone="+14088717984"
        address={{
          streetAddress: '340 Victory Lane',
          addressLocality: 'Lincoln',
          addressRegion: 'NE',
          postalCode: '68528',
          addressCountry: 'US',
        }}
      />
      <LogoJsonLd
        logo="https://content.speedwaymotors.com/OtherImages/Speedway_Motors_Logo_300.png"
        url="https://www.speedwaymotors.com"
      />
    </>
  )
}

export default HeadContent
